import { Location } from 'history';

export type FormContent = {
  amounts: number[];
  selectedAmount: number;
  redirectPath: string;
  originPath: string;
};

export const buildFormContent = (
  redirectPath: string,
  location: Location
): FormContent => ({
  redirectPath,
  originPath: `${location.pathname}${location.search}`,
  amounts: [200, 150, 100],
  selectedAmount: 200
});
