import { ButtonTheme, css, DefaultTheme, FontWeight } from 'styled-components';

import mq from '~/App/helpers/mq';
import {
  mail,
  print,
  x,
  facebook,
  caretRight,
  externalLinkIcon,
  downloadLinkIcon,
  close,
  link as linkIcon,
  linkedIn
} from '~/App/helpers/icons';

import {
  buttonStyles,
  ButtonStyle,
  ButtonSize
} from '~/App/config/buttonStyles';

const link = css`
  color: ${({ theme }) => theme.themeColors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  transition: color 0.3s ease-in-out;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.themeColors.primaryHover};
  }
`;

const spacer = css`
  > * {
    margin-top: 1.5rem;

    &:first-child {
      margin-top: 0;
    }
  }
`;

const clearfix = css`
  &:after,
  &:before {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
`;

const headingFont = css`
  font-family: 'CancerfondenSans', sans-serif;
`;

export type HeadingProps = {
  fontWeight?: FontWeight;
  clickable?: boolean;
};

const heading1 = css<HeadingProps>`
  ${headingFont};
  font-size: 2rem;
  line-height: 1.40625;
  font-weight: ${(props) =>
    props.fontWeight ? props.theme.fontWeights[props.fontWeight] : 700};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};

  ${mq('≥small')`
    font-size: 3.125rem;
    line-height: 1.2;
  `};
`;

const heading2 = css<HeadingProps>`
  ${headingFont};
  font-size: 1.625rem;
  line-height: 1.27;
  font-weight: ${(props) =>
    props.fontWeight ? props.theme.fontWeights[props.fontWeight] : 700};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};

  ${mq('≥small')`
    font-size: 2rem;
    line-height: 1.15625;
  `};
`;

const heading3 = css<HeadingProps>`
  ${headingFont};
  font-size: 1.375rem;
  line-height: 1.36;
  font-weight: ${(props) =>
    props.fontWeight ? props.theme.fontWeights[props.fontWeight] : 700};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};

  ${mq('≥small')`
    font-size: 1.375rem;
    line-height: 1.227272727;
  `};
`;

const heading4 = css<HeadingProps>`
  ${headingFont};
  font-size: 1rem;
  line-height: 1.25;
  font-weight: ${(props) =>
    props.fontWeight ? props.theme.fontWeights[props.fontWeight] : 700};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};

  ${mq('≥small')`
    font-size: 1.125rem;
    line-height: 1.222222222;
  `};
`;

const heading5 = css<HeadingProps>`
  ${headingFont};
  font-size: 1rem;
  line-height: 1.25;
  font-weight: ${(props) =>
    props.fontWeight ? props.theme.fontWeights[props.fontWeight] : 700};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};

  ${mq('≥small')`
    font-size: 1.125rem;
    line-height: 1.222222222;
  `};
`;

const heading6 = css<HeadingProps>`
  ${headingFont};
  font-size: 0.875rem;
  line-height: 1.285714286;
  font-weight: ${(props) =>
    props.fontWeight ? props.theme.fontWeights[props.fontWeight] : 700};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};

  ${mq('≥small')`
    font-size: 1rem;
    line-height: 1.25;
  `};
`;

const sectionHeader = css<HeadingProps>`
  ${headingFont};
  font-size: 0.875rem;
  text-transform: uppercase;
  line-height: 1.285714286;
  font-weight: ${(props) =>
    props.fontWeight ? props.theme.fontWeights[props.fontWeight] : 700};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};
  padding-bottom: 0.25rem;

  ${mq('≥small')`
    font-size: 1.125rem;
    line-height: 1.222222222;
    padding-bottom: 0.5rem;
  `};
`;

const iconLink = css`
  position: relative;
  display: inline-block;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.125rem;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  margin-top: 0.5rem;
  padding-right: 1rem;

  ${mq('≥small')`
    font-size: 1.125rem;
    line-height: 1.25rem;
  `};
`;

const iconLinkAfter = css`
  position: absolute;
  display: inline-block;
  padding-left: 0.375rem;
  font-size: 1.3125rem;
  line-height: inherit;
  text-align: center;
  transition: color 0.3s ease-in-out;
`;

const arrowLink = css`
  ${iconLink};
  color: ${({ theme }) => theme.themeColors.primary};

  &:hover {
    color: ${({ theme }) => theme.themeColors.primaryHover};
  }

  &::after {
    ${caretRight};
    ${iconLinkAfter};
    color: inherit;
  }

  &:hover::after {
    color: inherit;
  }
`;

const externalLink = css`
  ${iconLink};

  &::after {
    ${externalLinkIcon};
    ${iconLinkAfter};
    font-size: 1.125em;
    margin-top: -0.25rem;
  }
`;

const downloadLink = css`
  ${iconLink};

  &::after {
    ${downloadLinkIcon};
    ${iconLinkAfter};
    font-size: 1.3em;
    margin-top: -0.25rem;
  }
`;

const closeLink = css`
  ${iconLink};
  padding-right: 1.75rem;
  color: ${({ theme }) => theme.themeColors.primary};

  &:hover {
    color: ${({ theme }) => theme.themeColors.primaryHover};
  }

  &::after {
    ${close};
    ${iconLinkAfter};
    font-size: 1.5em;
    font-weight: ${(props) => props.theme.fontWeights.extraBold};
  }
`;

const contentTypeLink = css`
  display: inline-block;
  padding: 0.2em 0.5em;
  font-weight: ${(props) => props.theme.fontWeights.semiBold};

  ${mq('≥medium')`
    display: block;
  `};
`;

const linkUnderline = css`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    background: ${({ theme }) => theme.colors.white};
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease;
  }

  ${mq('≥large')`
    &:hover::after {
      visibility: visible;
      transform: scaleX(1);
    }
  `};
`;

const preamble = css`
  font-size: 1.188rem;
  ${headingFont} font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: 1.42;

  ${mq('≥small')`
    font-size: 1.375rem;
    line-height: 1.41;
  `};
`;

const labelText = css`
  ${headingFont} font-size: 1rem;
  line-height: 1.5;

  ${mq('≥small')`
    font-size: 1rem;
    line-height: 1.5;
  `};
`;

const description1 = css`
  font-size: 0.9375rem;

  i {
    font-style: italic;
  }

  em,
  b {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;

const description2 = css`
  font-size: 0.875rem;

  i {
    font-style: italic;
  }

  em,
  b {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;

const description3 = css`
  font-size: 0.75rem;

  i {
    font-style: italic;
  }

  em,
  b {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;

type ShareButtonColors = {
  invertedColors?: boolean;
};

const shareButtonColors = css<ShareButtonColors>`
  transition: color 0.3s ease, background-color 0.3s ease;
  color: ${({ theme, invertedColors }) =>
    invertedColors ? theme.colors.darkBlue : theme.colors.white};
  background-color: ${({ theme, invertedColors }) =>
    invertedColors ? theme.colors.white : theme.colors.darkBlue};

  &:hover {
    background-color: ${({ theme, invertedColors }) =>
      invertedColors ? null : theme.colors.hoverBlue};
    color: ${({ theme, invertedColors }) =>
      invertedColors ? theme.colors.hoverBlue : null};
  }
`;

const emailButton = css`
  ${shareButtonColors};
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  font-size: 1.625rem;
  line-height: 1.5;
  text-align: center;
  border-radius: 50%;

  &:before {
    ${mail};
    font-size: 1.25rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const printButton = css`
  ${shareButtonColors};
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  font-size: 1.625rem;
  line-height: 1.5;
  text-align: center;
  border-radius: 50%;

  &:before {
    ${print};
    font-size: 1.25rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const xButton = css`
  ${shareButtonColors};
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  font-size: 1.625rem;
  line-height: 1.5;
  text-align: center;
  border-radius: 50%;

  &:before {
    ${x};
    font-size: 1.625rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const facebookButton = css`
  ${shareButtonColors};
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  font-size: 1.625rem;
  line-height: 1.5;
  text-align: center;
  border-radius: 50%;

  &:before {
    ${facebook};
    font-size: 1.625rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const linkedInButton = css`
  ${shareButtonColors};
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  font-size: 1.625rem;
  line-height: 1.5;
  text-align: center;
  border-radius: 50%;

  &:before {
    ${linkedIn};
    font-size: 1.375rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const linkButton = css`
  ${shareButtonColors};
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  font-size: 1.625rem;
  line-height: 1.5;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  border: none;

  &:focus {
    outline: none;
  }

  &:before {
    ${linkIcon};
    font-size: 1.1rem;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export type PrimaryButtonProps = {
  buttonStyle?: ButtonStyle;
  buttonSize?: ButtonSize;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const getButtonTheme = (
  theme: DefaultTheme,
  style: ButtonStyle
): ButtonTheme => {
  switch (style) {
    case 'primary':
      return theme.primaryButton;
    case 'success':
      return theme.successButton;
    case 'cta':
      return theme.ctaButton;
    case 'disabled':
      return theme.disabledButton;
    case 'exerciseInverted':
      return theme.exerciseInvertedButton;
    case 'exercisePrimary':
      return theme.exercisePrimaryButton;
    case 'inverted':
      return theme.invertedButton;
    case 'outlineInverted':
      return theme.outlineInvertedButton;
    case 'outlinePrimary':
      return theme.outlinePrimaryButton;
    case 'error':
      return theme.errorButton;
  }
};

const primaryButton = css<PrimaryButtonProps>`
  position: relative;
  display: inline-block;
  min-width: 200px;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  font-family: 'CancerfondenSans', sans-serif;
  font-weight: 400;
  outline: 0;
  cursor: ${({ buttonStyle = buttonStyles.primary }) =>
    buttonStyle === buttonStyles.disabled ? 'default' : 'pointer'};
  background-color: transparent;
  line-height: 1;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  text-align: center;
  border-radius: 5rem;
  border-width: 2px;
  border-style: solid;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, filter 0.3s ease-in-out;

  ${({ theme, buttonStyle = buttonStyles.primary }) =>
    buttonStyle &&
    css`
      color: ${getButtonTheme(theme, buttonStyle).textColor};
      border-color: ${getButtonTheme(theme, buttonStyle).borderColor};
      background-color: ${getButtonTheme(theme, buttonStyle).backgroundColor};

      &:hover,
      &:focus {
        color: ${getButtonTheme(theme, buttonStyle).hoverTextColor};
        border-color: ${getButtonTheme(theme, buttonStyle).hoverBorderColor};
        background-color: ${getButtonTheme(theme, buttonStyle)
          .hoverBackgroundColor};
      }
    `};

  ${mq('<small')`
   padding: 0.5rem 1.5rem;
   font-size: 0.875rem;
   min-width: 142px;
   line-height: 0.75rem;
   `};

  ${({ isLoading }) =>
    isLoading &&
    css`
      padding-right: 3.375rem;
    `};

  ${({ isDisabled, isLoading, buttonStyle = buttonStyles.primary, theme }) =>
    (isDisabled || isLoading) &&
    css`
      color: ${getButtonTheme(theme, buttonStyle).disabledTextColor};
      border-color: ${getButtonTheme(theme, buttonStyle).disabledBorderColor};
      background-color: ${getButtonTheme(theme, buttonStyle)
        .disabledBackgroundColor};
      pointer-events: none;

      &:hover,
      &:focus {
        color: ${getButtonTheme(theme, buttonStyle).disabledTextColor};
        border-color: ${getButtonTheme(theme, buttonStyle).disabledBorderColor};
        background-color: ${getButtonTheme(theme, buttonStyle)
          .disabledBackgroundColor};
      }
    `};
`;

const outlinePrimaryButton = css`
  position: relative;
  display: inline-block;
  min-width: 200px;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  font-family: 'CancerfondenSans', sans-serif;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${(props) => props.theme.outlinePrimaryButton.textColor};
  border-color: ${(props) => props.theme.outlinePrimaryButton.borderColor};
  background-color: ${(props) =>
    props.theme.outlinePrimaryButton.backgroundColor};
  outline: 0;
  cursor: pointer;
  line-height: 1;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  text-align: center;
  border-radius: 5rem;
  border-width: 2px;
  border-style: solid;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;

  ${mq('<small')`
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  min-width: 142px;
  line-height: 0.75rem;
  `};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.outlinePrimaryButton.hoverTextColor};
    border-color: ${(props) =>
      props.theme.outlinePrimaryButton.hoverBorderColor};
    background-color: ${(props) =>
      props.theme.outlinePrimaryButton.hoverBackgroundColor};
  }
`;

const ctaButton = css`
  position: relative;
  display: inline-block;
  min-width: 200px;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  font-family: 'CancerfondenSans', sans-serif;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${(props) => props.theme.ctaButton.textColor};
  border-color: ${(props) => props.theme.ctaButton.borderColor};
  background-color: ${(props) => props.theme.ctaButton.backgroundColor};
  outline: 0;
  cursor: pointer;
  line-height: 1;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  text-align: center;
  border-radius: 5rem;
  border-width: 2px;
  border-style: solid;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;

  ${mq('<small')`
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  min-width: 142px;
  line-height: 0.75rem;
  `};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.ctaButton.hoverTextColor};
    border-color: ${(props) => props.theme.ctaButton.hoverBorderColor};
    background-color: ${(props) => props.theme.ctaButton.hoverBackgroundColor};
  }
`;

const invertedButton = css`
  position: relative;
  display: inline-block;
  min-width: 200px;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  font-family: 'CancerfondenSans', sans-serif;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${(props) => props.theme.invertedButton.textColor};
  border-color: ${(props) => props.theme.invertedButton.borderColor};
  background-color: ${(props) => props.theme.invertedButton.backgroundColor};
  outline: 0;
  cursor: pointer;
  line-height: 1;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  text-align: center;
  border-radius: 5rem;
  border-width: 2px;
  border-style: solid;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;

  ${mq('<small')`
    padding: 0.5rem 1.5rem;
    font-size: 0.875rem;
    min-width: 142px;
    line-height: 0.75rem;
    `};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.invertedButton.hoverTextColor};
    border-color: ${(props) => props.theme.invertedButton.hoverBorderColor};
    background-color: ${(props) =>
      props.theme.invertedButton.hoverBackgroundColor};
  }
`;

const visuallyHidden = css`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
`;

const card = css`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  padding: 1.5rem 1rem;

  ${mq('≥small')`
    padding: 2.5rem;
  `};
`;

const whiteGradient = css`
  content: '';
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  height: 75px;
  width: 100vw;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%);

  ${mq('≥small')`
    content: none;
  `};
`;

export {
  link,
  spacer,
  clearfix,
  headingFont,
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  heading6,
  sectionHeader,
  arrowLink,
  externalLink,
  downloadLink,
  closeLink,
  contentTypeLink,
  linkUnderline,
  preamble,
  labelText,
  description1,
  description2,
  description3,
  emailButton,
  printButton,
  xButton,
  primaryButton,
  outlinePrimaryButton,
  ctaButton,
  invertedButton,
  facebookButton,
  linkedInButton,
  linkButton,
  visuallyHidden,
  card,
  whiteGradient
};
