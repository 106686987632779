import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo
} from 'react';
import { Helmet } from 'react-helmet';

// Constants
import { SwishType } from '~/App/config/swishTypes';
import { errorLevels } from '~/App/config/errorLevels';
import { gtmDetails, gtmCustomDetails } from '~/App/config/gtmDetails';
import { Width } from '~/types/Block';

// Helpers
import { logMessage } from '~/App/helpers/logger';
import {
  EecData,
  eecProduct,
  pushEecEvent,
  pushGTMEvent,
  pushGTMFormEvent
} from '~/App/helpers/gtm-helper';
import { gtmCustomEventCategories } from '~/App/config/gtmCustomEvents';

import { orders, purchases } from '~/App/helpers/http';

// Shared components
import { Redirect } from '~/App/shared/components/Redirect';

import { useQuery } from '~/App/shared/hooks/use-query';
import { useInterval } from '~/App/shared/hooks/use-interval';

// Local components
import { Instructions } from './components/Instructions';
import { SwishBlockInstructions } from '../../shared/components/Donation/SwishBlockDonation/components/SwishBlockInstructions';

type FetchStatus = 'idle' | 'fetching' | 'completed';
type PaymentStatus = 'unknown' | 'pending' | 'paid' | 'failed' | 'timedout';
type Response = {
  status: PaymentStatus;
  eec?: EecData;
};

type Props = {
  publicToken?: string;
  isSwishBlock?: boolean;
  swishType?: SwishType;
  width: Width;
  headingSettings?: {
    fontSize: string;
  };
  handleChange?: () => void | null;
};
type Query = {
  originPath: string;
  redirectPath: string;
  token?: string; // mw purchase
  order?: string; // commerce purchase
  gtmId?: string;
  type: SwishType;
  amount?: string;
  step?: string;
  paymentMethod?: string;
  formName?: string;
  period?: string;
};

export default function ConfirmSwishPayment({
  publicToken,
  width,
  headingSettings,
  isSwishBlock,
  swishType,
  handleChange
}: Props) {
  const redirectTimeoutId = useRef<ReturnType<typeof setTimeout>>();
  const tick = useInterval(2500);

  const {
    token,
    order,
    originPath,
    redirectPath,
    type,
    gtmId,
    amount,
    step,
    paymentMethod,
    formName,
    period
  } = useQuery<Query>();

  const [retries, setRetries] = useState(0);
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>('idle');
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>('unknown');
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const cancelRedirectTimeout = useCallback(() => {
    if (redirectTimeoutId.current) {
      clearTimeout(redirectTimeoutId.current);
    }
  }, []);

  const preformRedirect = useCallback(() => {
    cancelRedirectTimeout();
    setShouldRedirect(true);
  }, [cancelRedirectTimeout]);

  const startRedirectTimeout = useCallback(() => {
    redirectTimeoutId.current = setTimeout(preformRedirect, 2100);
  }, [preformRedirect]);

  const handleSuccessResponse = useCallback(
    ({ status, eec }: Response) => {
      const isPending = paymentStatus === 'pending';

      if (status === 'paid') {
        if (isPending && eec) {
          pushEecEvent('purchase', eec);
        }
        if (isSwishBlock) {
          pushGTMEvent({
            category: gtmCustomEventCategories.swishBlock._category,
            action: gtmCustomEventCategories.swishBlock._action,
            label: gtmCustomEventCategories.swishBlock._label
          });
        }

        const parsedString = (value: string | undefined) => {
          if (value) {
            const newValue = parseInt(value, 10);
            if (isNaN(newValue)) {
              return undefined;
            }
            return newValue;
          }
        };
        pushGTMFormEvent('formComplete', {
          formName,
          amount: parsedString(amount),
          period,
          step: parsedString(step),
          stepName: 'Välj betalsätt',
          paymentMethod
        });

        setFetchStatus('completed');
        setPaymentStatus('paid');
        startRedirectTimeout();
        return;
      }

      if (status === 'failed') {
        logMessage({
          level: errorLevels.error,
          message: `Swish purchase failed: Payment state 'failed'`
        });

        setFetchStatus('completed');
        setPaymentStatus('failed');
        return;
      }

      setRetries((state) => state + 1);
      setPaymentStatus(status);
      setFetchStatus('idle');
    },
    [
      isSwishBlock,
      paymentStatus,
      startRedirectTimeout,
      formName,
      amount,
      step,
      period,
      paymentMethod
    ]
  );

  const handleErrorResponse = useCallback(
    (error) => {
      logMessage({
        level: errorLevels.error,
        message: `Swish purchase failed: ${error}`,
        data: {
          purchaseToken: token,
          order: order,
          error: error
        }
      });
      setFetchStatus('completed');
      setPaymentStatus('failed');
    },
    [token, order]
  );

  const fetchMwPurchase = useCallback(
    async (token: string): Promise<Response> => {
      const { data } = await purchases.show({ slug: token });
      const { purchase: { payment, ...purchase } = {} } = data;

      const id = isSwishBlock ? '3c' : gtmId;
      const gtm = id
        ? gtmCustomDetails[id]
        : data.product.id
        ? gtmDetails[data.product.id]
        : {};

      const isComplete =
        payment?.state === 'paid' || payment?.state === 'filed';

      if (isComplete) {
        return {
          status: 'paid',
          eec: {
            actionField: {
              id: purchase.id,
              revenue: purchase.amount
            },
            products: [
              {
                id: data.product?.id,
                price: purchase.amount,
                quantity: purchase.product?.quantity || 1,
                ...gtm
              }
            ]
          }
        };
      }

      if (payment?.state === 'failed') {
        return {
          status: 'failed'
        };
      }

      return {
        status: 'pending'
      };
    },
    [gtmId, isSwishBlock]
  );

  const fetchCommerce = useCallback(
    async (orderId: string): Promise<Response> => {
      const payment = await orders.status(orderId);

      if (payment.data.status === 'Failed') {
        return {
          status: 'failed'
        };
      }

      if (payment.data.status === 'Pending') {
        return {
          status: 'pending'
        };
      }

      const order = await orders.get(orderId);

      return {
        status: 'paid',
        eec: {
          products: order.data.orderLines.map(eecProduct),
          actionField: {
            id: orderId,
            affiliation: 'Gåvoshop',
            revenue: order.data.totalPrice,
            shipping: order.data.shippingPrice
          }
        }
      };
    },
    []
  );

  useEffect(() => {
    if (fetchStatus !== 'idle') return;
    if (paymentStatus === 'timedout') return;

    if (retries >= 240) {
      setFetchStatus('completed');
      setPaymentStatus('timedout');
      return;
    }

    setFetchStatus('fetching');
    if (publicToken && isSwishBlock) {
      fetchMwPurchase(publicToken)
        .then(handleSuccessResponse)
        .catch(handleErrorResponse);
    }
    if (token) {
      fetchMwPurchase(token)
        .then(handleSuccessResponse)
        .catch(handleErrorResponse);
    } else if (order) {
      fetchCommerce(order)
        .then(handleSuccessResponse)
        .catch(handleErrorResponse);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tick]);

  const redirects = useMemo(() => {
    if (shouldRedirect && token) {
      return <Redirect to={`${redirectPath}?purchaseToken=${token}`} />;
    }
    if (shouldRedirect && !isSwishBlock) {
      return <Redirect to={redirectPath} />;
    }

    return null;
  }, [isSwishBlock, redirectPath, shouldRedirect, token]);

  return (
    <div>
      <Helmet title="Bekräfta betalning" />
      {isSwishBlock ? (
        <SwishBlockInstructions
          paymentStatus={paymentStatus}
          handleClick={handleChange}
          isDesktop={swishType === 'ecommerce'}
          width={width}
          headingSettings={headingSettings}
        />
      ) : (
        <>
          <Instructions
            type={type ?? swishType}
            originPath={originPath}
            failed={paymentStatus === 'failed'}
          />
          {redirects}
        </>
      )}
    </div>
  );
}
