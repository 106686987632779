import React, { RefObject, useCallback, Fragment, useMemo } from 'react';
import stringify from 'json-stringify-safe';
import { ThemeProvider } from 'styled-components';
import { merge } from 'lodash';

// Helpers
import * as themes from '~/App/config/themes';
import { blockTemplates } from '~/App/config/blockTemplates';

import {
  FAQ,
  Graph,
  Wufoo,
  Lawly,
  Thanks,
  Content,
  Divider,
  FileList,
  LinkList,
  DataBlock,
  BlockHero,
  Shorthand,
  Slideshow,
  BlockImage,
  ShareBlock,
  TwoUpMedia,
  Supporters,
  Breadcrumbs,
  ProductGrid,
  StepBlock,
  ProductList,
  RelatedBlock,
  EmailLeads,
  SwishBlock
} from '~/App/shared/components/Blocks';

import { AuthorHelper } from '~/App/shared/components/AuthorHelper';

type Theme = 'pink' | 'global' | 'standard' | 'christmas';
type Block = {
  id: string;
  hash: string;
  name: string;
  template: string;
  themeName?: Theme;

  publishState: string;
} & any;

type Props = {
  heroRef?: RefObject<HTMLDivElement>;
  blocks: Block[];
  purchase?: any;
  collection?: any;
};

export default function BlockArea({
  blocks = [],
  collection,
  purchase,
  heroRef
}: Props) {
  const key = useCallback((block: any) => {
    try {
      const string = stringify(block);
      let hash = 0,
        i,
        chr;

      for (i = 0; i < string.length; i += 1) {
        chr = string.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }

      return hash;
    } catch (error) {
      return block.hash;
    }
  }, []);

  const renderBlock = useCallback(
    (block: Block, index: number) => {
      // @ts-expect-error
      const customTheme = themes[block.themeName] || {};
      const theme = merge({}, themes.global, customTheme);

      return (
        <div key={key(block)} id={block.hash ? block.hash : index}>
          <ThemeProvider theme={theme}>
            <AuthorHelper
              id={block.id}
              path="/blocks/"
              text={block.name}
              publishState={block.publishState}
            >
              <Fragment>
                {block.template === blockTemplates.faq && <FAQ {...block} />}
                {block.template === blockTemplates.hero && (
                  <div ref={heroRef}>
                    <BlockHero {...block} />
                  </div>
                )}
                {block.template === blockTemplates.image && (
                  <BlockImage {...block} />
                )}
                {block.template === blockTemplates.graph && (
                  <Graph {...block} />
                )}
                {block.template === blockTemplates.thanks && (
                  <Thanks
                    {...block}
                    purchase={purchase}
                    collection={collection}
                  />
                )}
                {block.template === blockTemplates.content && (
                  <Content {...block} />
                )}
                {block.template === blockTemplates.related && (
                  <RelatedBlock {...block} />
                )}
                {block.template === blockTemplates.divider && (
                  <Divider {...block} />
                )}
                {block.template === blockTemplates.fileList && (
                  <FileList {...block} />
                )}
                {block.template === blockTemplates.linkList && (
                  <LinkList {...block} />
                )}
                {block.template === blockTemplates.dataBlock && (
                  <DataBlock {...block} />
                )}
                {block.template === blockTemplates.twoUpMedia && (
                  <TwoUpMedia {...block} />
                )}
                {block.template === blockTemplates.supporters && (
                  <Supporters {...block} />
                )}
                {block.template === blockTemplates.wufoo && (
                  <Wufoo {...block} />
                )}
                {block.template === blockTemplates.breadcrumbs && (
                  <Breadcrumbs {...block} />
                )}
                {block.template === blockTemplates.productGrid && (
                  <ProductGrid {...block} />
                )}
                {block.template === blockTemplates.slideshow && (
                  <Slideshow {...block} />
                )}
                {block.template === blockTemplates.share && (
                  <ShareBlock {...block} />
                )}
                {block.template === blockTemplates.shorthand && (
                  <Shorthand {...block} />
                )}
                {block.template === blockTemplates.step && (
                  <StepBlock {...block} />
                )}
                {block.template === blockTemplates.productList && (
                  <ProductList {...block} />
                )}
                {block.template === blockTemplates.lawly && (
                  <Lawly {...block} />
                )}
                {block.template === blockTemplates.emailLeads && (
                  <EmailLeads {...block} />
                )}
                {block.template === blockTemplates.swish && (
                  <SwishBlock {...block} />
                )}
              </Fragment>
            </AuthorHelper>
          </ThemeProvider>
        </div>
      );
    },
    [collection, heroRef, key, purchase]
  );

  const children = useMemo(() => blocks.map(renderBlock), [
    renderBlock,
    blocks
  ]);

  return <Fragment>{children}</Fragment>;
}
