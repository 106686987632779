import React, { ReactNode } from 'react';
import styled from 'styled-components';

import mq from '~/App/helpers/mq';
import { HeadingProps, heading1, heading3 } from '~/App/helpers/mixins';

import { MonthlyKlarnaDonationGala } from '~/App/shared/components/Donation/MonthlyKlarnaDonationGala';
import { IntegrityText } from '~/App/shared/components/Donation/components/Forms/components/IntegrityText';

const Heading1 = styled.h1<HeadingProps>`
  ${heading1};
  color: ${({ theme }) => theme.colors.white};
  margin: 5rem auto 1rem auto;
  text-align: center;
`;
const Heading3 = styled.h3<HeadingProps>`
  ${heading3};
  color: ${({ theme }) => theme.colors.white};
  margin: 1rem auto 4rem auto;
  text-align: center;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  text-align: center;
  margin: 1rem auto 3rem auto;

  ${mq('<medium')`
    font-size: 1rem;
    margin: 1rem auto 1rem auto;

  `};
`;

const Wrapper = styled.div`
  width: 48rem;
  max-width: calc(100% - 2rem);
  margin: 0 auto;
`;

const Card = styled.div`
  padding: 1.5rem;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 8.5rem;
  border-radius: 4px;

  ${mq('≥small')`
    padding: 1.5rem 0rem;
    margin-top: 2rem;
  `};
`;

const Image = styled.img`
  max-width: calc(100% - 8rem);
  margin: 1rem auto 0;
  display: flex;

  ${mq('≥small')`
    margin: 3rem 4rem 0;
`};
`;

const CardContent = styled.div`
  width: 100%;
  max-width: 29rem;
`;

const CardHeading = styled.h2`
  ${heading3};
  color: ${({ theme }) => theme.colors.charcoal};
  margin: 1rem 0 2rem;
  text-align: center;
`;

const StyledIntegrityText = styled(IntegrityText)`
  margin-top: 2rem;
`;

type Props = {
  source?: string;
  redirectPath: string;
  children: ReactNode;
};

export function Form({ source, redirectPath, children }: Props) {
  return (
    <Wrapper>
      {/* <Image src="https://res.cloudinary.com/cancerfonden/image/upload/f_auto,q_auto:eco/v1701178197/assets/STMC-logga-takeover_cropped.png" />
      <Text>Alla behövs i kampen mot cancer – bli månadsgivare!</Text> */}
      <Heading1>Bli månadsgivare</Heading1>
      <Heading3>Tillsammans besegrar vi cancer</Heading3>
      <Card>
        <CardContent>
          <CardHeading>Hur mycket vill du ge varje månad?</CardHeading>
          <MonthlyKlarnaDonationGala
            redirectPath={redirectPath}
            source={source}
          />
          <StyledIntegrityText />
          {children}
        </CardContent>
      </Card>
    </Wrapper>
  );
}
