import { DefaultTheme } from 'styled-components';

import colors from './colors';
import iconAssets from './iconAssets';

export default {
  colors: colors,
  fontWeights: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900
  },
  themeColors: {
    primary: colors.darkBlue,
    primaryHover: colors.hoverBlue,
    fadedPrimary: colors.polar,
    accentedBackgroundColor: colors.seashell,
    primaryBackgroundColor: colors.lightPolar,
    backgroundPink: colors.amour,
    backgroundGreen: colors.lightGreen,
    accentBlue: colors.pastelBlue,
    accentPink: colors.pastelPink,
    accentGreen: colors.pastelGreen,
    accentYellow: colors.accentYellow
  },
  faq: {
    headingColor: colors.charcoal
  },
  hero: {
    darkTextColor: colors.darkBlue,
    lightTextColor: colors.white
  },
  related: {
    headingColor: colors.charcoal
  },
  content: {
    headingColor: colors.charcoal
  },
  divider: {
    color: colors.alto,
    alternativeColor: colors.seashell
  },
  newsCard: {
    legendBackgroundColor: colors.darkBlue
  },
  packageCard: {
    legendColor: colors.darkBlue,
    borderColor: colors.silver,
    backgroundColor: colors.white,
    alternateBackgroundColor: colors.lightPolar
  },
  sponsorCard: {
    borderColor: colors.silver,
    backgroundColor: colors.white,
    alternateBackgroundColor: colors.seashell
  },
  partnerCard: {
    borderColor: colors.silver,
    backgroundColor: colors.white,
    alternateBackgroundColor: colors.seashell
  },
  collectionItem: {
    textColor: colors.charcoal,
    imageBackground: colors.transparent,
    imageAlternativeBackground: colors.azalea,
    primaryColor: colors.lightBlue,
    statusColor: colors.darkBlue,
    progressBarColor: colors.pastelBlue,
    progressColor: colors.darkBlue,
    settingsLinkColor: colors.darkBlue,
    contentBackgroundColor: colors.white,
    summaryExampleBackgroundColor: colors.seashell
  },
  arrowLink: {
    textColor: colors.darkBlue,
    iconColor: colors.white,
    iconBackgroundColor: colors.darkBlue
  },
  externalLink: {
    textColor: colors.darkBlue,
    iconColor: colors.darkBlue
  },
  primaryButton: {
    textColor: colors.white,
    backgroundColor: colors.darkBlue,
    borderColor: colors.darkBlue,
    hoverTextColor: colors.white,
    hoverBackgroundColor: colors.hoverBlue,
    hoverBorderColor: colors.hoverBlue,
    disabledTextColor: colors.white,
    disabledBackgroundColor: colors.silverChalice,
    disabledBorderColor: colors.silverChalice,
    spinnerColorLight: colors.smoke,
    spinnerColorDark: colors.silver
  },
  successButton: {
    textColor: colors.white,
    backgroundColor: colors.darkApple,
    borderColor: colors.darkApple,
    hoverTextColor: colors.white,
    hoverBackgroundColor: colors.lightApple,
    hoverBorderColor: colors.lightApple,
    disabledTextColor: colors.white,
    disabledBackgroundColor: colors.silverChalice,
    disabledBorderColor: colors.silverChalice,
    spinnerColorLight: colors.smoke,
    spinnerColorDark: colors.silver
  },
  ctaButton: {
    textColor: colors.white,
    backgroundColor: colors.darkApple,
    borderColor: colors.darkApple,
    hoverTextColor: colors.white,
    hoverBackgroundColor: colors.lightApple,
    hoverBorderColor: colors.lightApple,
    disabledTextColor: colors.white,
    disabledBackgroundColor: colors.silverChalice,
    disabledBorderColor: colors.silverChalice,
    spinnerColorLight: colors.smoke,
    spinnerColorDark: colors.silver
  },
  invertedButton: {
    textColor: colors.darkBlue,
    backgroundColor: colors.white,
    borderColor: colors.white,
    hoverTextColor: colors.darkBlue,
    hoverBackgroundColor: colors.polar,
    hoverBorderColor: colors.polar,
    disabledTextColor: colors.darkBlue,
    disabledBackgroundColor: colors.silverChalice,
    disabledBorderColor: colors.silverChalice,
    spinnerColorLight: colors.smoke,
    spinnerColorDark: colors.silver
  },
  outlinePrimaryButton: {
    textColor: colors.darkBlue,
    backgroundColor: colors.transparent,
    borderColor: colors.darkBlue,
    hoverTextColor: colors.white,
    hoverBackgroundColor: colors.darkBlue,
    hoverBorderColor: colors.darkBlue,
    disabledTextColor: colors.darkBlue,
    disabledBackgroundColor: colors.transparent,
    disabledBorderColor: colors.darkBlue,
    spinnerColorLight: colors.smoke,
    spinnerColorDark: colors.silver
  },
  outlineInvertedButton: {
    textColor: colors.white,
    backgroundColor: colors.transparent,
    borderColor: colors.white,
    hoverTextColor: colors.darkBlue,
    hoverBackgroundColor: colors.white,
    hoverBorderColor: colors.white,
    disabledTextColor: colors.white,
    disabledBackgroundColor: colors.transparent,
    disabledBorderColor: colors.white,
    spinnerColorLight: colors.smoke,
    spinnerColorDark: colors.silver
  },
  disabledButton: {
    textColor: colors.white,
    backgroundColor: colors.silverChalice,
    borderColor: colors.silverChalice,
    hoverTextColor: colors.white,
    hoverBackgroundColor: colors.silverChalice,
    hoverBorderColor: colors.silverChalice,
    disabledTextColor: colors.white,
    disabledBackgroundColor: colors.silverChalice,
    disabledBorderColor: colors.silverChalice,
    spinnerColorLight: colors.smoke,
    spinnerColorDark: colors.silver
  },
  errorButton: {
    textColor: colors.white,
    backgroundColor: colors.errorRed,
    borderColor: colors.errorRed,
    hoverTextColor: colors.white,
    hoverBackgroundColor: colors.errorRedLight,
    hoverBorderColor: colors.errorRedLight,
    disabledTextColor: colors.white,
    disabledBackgroundColor: colors.silverChalice,
    disabledBorderColor: colors.silverChalice,
    spinnerColorLight: colors.smoke,
    spinnerColorDark: colors.silver
  },
  exercisePrimaryButton: {
    textColor: colors.white,
    backgroundColor: colors.darkBlue,
    borderColor: colors.darkBlue,
    hoverTextColor: colors.white,
    hoverBackgroundColor: colors.hoverBlue,
    hoverBorderColor: colors.hoverBlue,
    disabledTextColor: colors.white,
    disabledBackgroundColor: colors.silverChalice,
    disabledBorderColor: colors.silverChalice,
    spinnerColorLight: colors.smoke,
    spinnerColorDark: colors.silver
  },
  exerciseInvertedButton: {
    textColor: colors.darkBlue,
    backgroundColor: colors.white,
    borderColor: colors.white,
    hoverTextColor: colors.darkBlue,
    hoverBackgroundColor: colors.polar,
    hoverBorderColor: colors.polar,
    disabledTextColor: colors.darkBlue,
    disabledBackgroundColor: colors.silverChalice,
    disabledBorderColor: colors.silverChalice,
    spinnerColorLight: colors.smoke,
    spinnerColorDark: colors.silver
  },
  navLink: {
    hoverColor: colors.oceanBlue
  },
  navItem: {
    color: colors.darkBlue,
    activeColor: colors.oceanBlue,
    activeBackgroundColor: colors.smoke
  },
  subNavLink: {
    hoverColor: colors.hoverBlue
  },
  subNavItem: {
    color: colors.darkBlue,
    activeColor: colors.white,
    activeBackgroundColor: colors.oceanBlue
  },
  subNavigation: {
    backgroundColor: colors.seashell,
    shadowColor: colors.gallery
  },
  iconAssets: {
    check: iconAssets.checkStandard
  },
  exerciseApp: {
    primaryThemeColor: colors.lightBlue,
    secondaryThemeColor: colors.botticelli,
    thirdThemeColor: colors.danube,
    cardBackgroundColor: colors.white,
    primaryHeadingColor: colors.darkBlue,
    secondaryHeadingColor: colors.charcoal,
    darkTextColor: colors.charcoal,
    lightTextColor: colors.white,
    progressBackgroundColor: colors.seashell,
    progressForegroundColor: colors.darkBlue,
    linkColor: colors.darkBlue,
    linkHoverColor: colors.blueBell,
    imageBackgroundColor: colors.pastelBlue
  },
  swishSpinner: {
    primaryColor: colors.darkBlue,
    secondaryColor: colors.lightDust
  },
  bankIdSpinner: {
    primaryColor: colors.darkBlue,
    secondaryColor: colors.lightDust
  }
} as DefaultTheme;
