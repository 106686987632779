import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

// Constants
import { buttonStyles } from '~/App/config/buttonStyles';
import { paymentMethods } from '~/App/config/paymentMethods';

// Import shared components
import { PrimaryButton } from '~/App/shared/components/Elements';
import { IValidation } from '~/App/shared/components/Validation';
import { PaymentValues } from '../../States';

const Wrapper = styled.div`
  text-align: center;
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: 1rem;
  min-width: 214.07px; // match width of "Swish på annan enhet?"
`;

type Props = {
  children?: ReactNode;
  values: PaymentValues;
  validation: IValidation;
  isDisabled?: boolean;
  submit: {
    isSending: boolean;
    isPolling: boolean;
    isCompleted: boolean;
  };
};

export function SubmitButton({
  children,
  values,
  validation,
  submit,
  isDisabled
}: Props) {
  const content = useMemo(
    () =>
      children
        ? children
        : values.paymentMethod?.id === paymentMethods.swish
        ? 'Betala med Swish'
        : 'Betala och slutför',
    [children, values.paymentMethod?.id]
  );

  return (
    <Wrapper>
      <StyledButton
        buttonStyle={
          validation.isValidated && !isDisabled
            ? buttonStyles.success
            : buttonStyles.disabled
        }
        isLoading={submit.isSending || submit.isPolling || submit.isCompleted}
        isDisabled={
          submit.isSending ||
          submit.isPolling ||
          submit.isCompleted ||
          isDisabled
        }
        type="submit"
        children={content}
      />
    </Wrapper>
  );
}
