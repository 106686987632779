const cloudinaryBase = 'https://res.cloudinary.com/cancerfonden/image/upload';

export const hero = `${cloudinaryBase}/v1457618284/curatedimages/default-hero.jpg`;
export const collection = [
  `${cloudinaryBase}/v1604664514/defaultheroimages/collection/motivbild-minnes-2.jpg`,
  `${cloudinaryBase}/v1610465704/defaultheroimages/collection/motivbild-ballong.jpg`
];

export const genericCertificate = [
  `${cloudinaryBase}/v1614761790/gift-certificate-images/hj-rtmotiv.svg`,
  `${cloudinaryBase}/v1589794138/gift-certificate-images/gift_flowers.svg`,
  `${cloudinaryBase}/v1614761790/gift-certificate-images/paketmotiv.svg`
];

export const memory = [
  `${cloudinaryBase}/v1604664514/defaultheroimages/collection/motivbild-minnes.jpg`
];

export const memorial = [
  `${cloudinaryBase}/v1645691004/assets/memorial-gift/cf-minnessidan-org5315_3.jpg`
];
