import { ICommerceOrder } from '~/types/ICommerceOrder';
import { ICommercePayment } from '~/types/ICommercePayment';
import { siteAxios } from '.';

export const orders = {
  checkout: (order: ICommerceOrder) => {
    return siteAxios.post<ICommerceOrder>('/api/v2/checkout', order);
  },
  get: (id: string, params?: Record<string, unknown>) => {
    return siteAxios.get<ICommerceOrder>(`/api/v2/orders/${id}`, { params });
  },
  getOrderPaymentInfo: (id: string ) => {
    return siteAxios.get<ICommerceOrder>(`/api/v2/orders/${id}/orderPaymentInfo`);
  },
  status: (id: string, params?: Record<string, unknown>) => {
    return siteAxios.get<ICommercePayment>(`/api/v2/orders/${id}/status`, { params });
  },
  all: () => {
    return siteAxios.get<ICommercePayment>(`/api/v2/orders/`);
  }
};
