import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { heading1 } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';

import { MemorialModal } from './MemorialModal';
import { buttonStyles } from '~/App/config/buttonStyles';

import { PrimaryButton } from '~/App/shared/components/Elements';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/c_fill,dpr_2.0,fl_progressive,h_360,q_80/v1632993687/assets/hero_insamlingar.jpg');
  background-position: 80%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 22.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;
`;

const Headline = styled.h1`
  ${heading1};
  font-size: 2.5rem;
  line-height: 1.15625;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  ${mq('≥large')`
    font-size: 5rem;
    line-height: 1.125;
  `};
`;

const ButtonsWrapper = styled.div`
  display: none;
  margin: 1.5rem auto 0;
  padding: 0 1rem;
  text-align: center;
  max-width: 15rem;

  ${mq('≥small')`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 0;
    max-width: none;
  `};
`;

const Button = styled(PrimaryButton)`
  margin: 0 0 1.5rem;

  &:last-child {
    margin: 0;
  }

  ${mq('≥small')`
    margin: 0 1.5rem 0 0;
  `};
`;

const BringToTop = styled.div`
  z-index: 110;
`;

export function Hero() {
  const [modalOpen, setOpen] = useState(false);

  const openModal = useCallback(() => setOpen(true), []);
  const closeModal = useCallback(() => setOpen(false), []);

  const modal = useMemo(() => {
    if (!modalOpen) return null;

    return <MemorialModal closeModal={closeModal} />;
  }, [modalOpen, closeModal]);

  return (
    <Column>
      <Wrapper>
        <Content>
          <Headline children="Insamlingar" />
          <ButtonsWrapper onClick={openModal}>
            <Button
              buttonType="link"
              buttonStyle={buttonStyles.inverted}
              children="Starta insamling"
            />
          </ButtonsWrapper>
        </Content>
        <BringToTop children={modal} />
      </Wrapper>
    </Column>
  );
}
