import React from 'react';
import styled from 'styled-components';

import { buttonStyles } from '~/App/config/buttonStyles';

import { KlarnaPaymentsFields } from '../../components/PaymentMethods/KlarnaPayments';
import AmountSelector from '../../components/Forms/components/AmountSelector';
import {
  AlertMessageContainer,
  PrimaryButton
} from '~/App/shared/components/Elements';

import { FormContent } from '../formContent';
import { ISubmit } from '../hooks/useSubmit';
import { IValidation } from '../../../Validation';
import { PaymentValues, ProductValues } from '../../components/States';
import { mq } from '~/lib/mq';

const Wrapper = styled.form`
  width: 100%;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  ${mq('<small')`
    min-width: max(200px, 80%);
  `};
`;

const Border = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.lightDust};
  margin: 3rem 0 0rem;
  width: 100%;
`;

type Props = {
  formContent: FormContent;
  values: PaymentValues & ProductValues;
  validation: IValidation;
  submit: ISubmit;
};

export function Form({ formContent, values, validation, submit }: Props) {
  return (
    <Wrapper onSubmit={submit.handleSubmit}>
      <AmountSelector
        amounts={formContent.amounts}
        selectedAmount={values.productOptions.product.price}
        customAmount={values.productOptions.product.customPrice}
        setAmount={values.handlers.setProductPrice}
        setCustomAmount={values.handlers.setProductCustomPrice}
        resetAmount={values.handlers.resetProductPrice}
        validationKey={'productOptions.product.price'}
        validation={validation}
        largeLayout="auto"
        smallLayout="auto"
        tinyLayout="100"
      />

      <Border />

      <KlarnaPaymentsFields values={values} />

      {submit.error && !submit.isSending && (
        <Center>
          <AlertMessageContainer messageStyle="error">
            {submit.error}
          </AlertMessageContainer>
        </Center>
      )}

      <Center>
        <StyledPrimaryButton
          type="submit"
          isLoading={submit.isSending}
          buttonStyle={
            validation.isValidated ? buttonStyles.cta : buttonStyles.disabled
          }
          children="Betala och slutför"
        />
      </Center>
    </Wrapper>
  );
}
