import React, { useMemo } from 'react';

import { usePageContent } from '~/App/shared/hooks/use-page-content';

import { CommerceThankYou } from './CommerceThankYou';
import { LoadData } from './CommerceThankYou.LoadData';

const View = (props: unknown) => {
  const pageContent = usePageContent();
  const children = useMemo(
    () =>
      pageContent ? <CommerceThankYou {...pageContent} {...props} /> : null,
    [pageContent, props]
  );

  return children;
};

View.loadData = LoadData;

export default View;
