import { useRef, useCallback, useMemo, useState, RefObject } from 'react';

import { loadScript } from '~/App/helpers/load-script';
import { klarna } from '~/App/helpers/http';
import { siteUrl } from '~/App/helpers/env';
import { paymentMethods } from '~/App/config/paymentMethods';
import { Period } from '~/types/IPurchase';
import { parse, stringify } from '~/lib/query-string';
import { useShoppingCart } from '~/App/contexts/ShoppingCart';

type Container = HTMLDivElement;

export type State = {
  showForm: boolean;
  isReady: boolean;
  container: RefObject<Container>;
  sessionId?: string;
};

export type KlarnaPayments = State & {
  handlers: {
    klarnaPaymentsInit: (
      amount: number,
      isGiftShop: boolean
    ) => Promise<SessionResponse>;
    klarnaPaymentsLoad: (
      amount?: number | null
    ) => Promise<LoadCallbackResponse>;
    klarnaPaymentsUpdateSession: (amount: number) => Promise<void>;
    klarnaPaymentsAuthorize: (
      data?: Record<string, unknown>
    ) => Promise<AuthorizeCallbackResponse>;
    klarnaPaymentsPrefetch: () => Promise<IKlarna | undefined>;
  };
};

type PaymentMethodCategory = {
  identifier: string;
  name: string;
};

interface SessionResponse {
  id: string;
  client_token: string;
  payment_method_categories: PaymentMethodCategory[];
}

interface ErrorResponse {
  Error?: {
    invalid_fields: string[];
  };
}

interface InitOptions {
  client_token: string;
}

interface LoadOptions {
  container: Container;
  payment_method_category?: string;
}

interface LoadCallbackResponse extends ErrorResponse {
  show_form: boolean;
}

interface AuthorizeOptions {
  auto_finalize?: boolean;
  payment_method_category?: string;
}

interface AuthorizeCallbackResponse extends ErrorResponse {
  authorization_token?: string;
  approved: boolean;
  show_form: boolean;
  finalize_required?: boolean;
}

interface IKlarna {
  Payments: {
    init: (options: InitOptions) => void | never;
    load: (
      options: LoadOptions,
      data: Record<string, unknown>,
      callback: (res: LoadCallbackResponse) => void
    ) => void | never;
    authorize: (
      options: AuthorizeOptions,
      data: Record<string, unknown>,
      callback: (res: AuthorizeCallbackResponse) => void | never
    ) => void | never;
  };
}

declare let Klarna: IKlarna | undefined;

type OptionsCache = Partial<Record<Period, SessionResponse>>;

type Props = {
  redirectPath: string;
  period: Period;
};

export function useKlarnaPayments({ redirectPath, period }: Props) {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [optionsCache, setOptionsCache] = useState<OptionsCache>({});
  const shoppingCart = useShoppingCart();

  const container = useRef<Container>(null);
  const paymentMethodCategory = useRef<string>();

  const name = useMemo(
    () => (period === 'Month' ? 'Månadsgivare' : 'Engångsgåva'),
    [period]
  );

  const getInstance = useCallback(async () => {
    if (typeof window === 'undefined') {
      return undefined;
    }

    await loadScript({ url: 'https://x.klarnacdn.net/kp/lib/v1/api.js' });

    return Klarna;
  }, []);

  const createInitOptions = useCallback(
    async (amount: number, isGiftShop: boolean): Promise<SessionResponse> => {
      const cachedValue = optionsCache[period];

      if (cachedValue) {
        return cachedValue;
      }

      const [path, search = ''] = redirectPath.split('?');
      const query = parse(search);
      const newSearch = stringify({
        ...query,
        purchaseToken: '{order.id}',
        paymentMethodId: paymentMethods.klarnaPayments
      });

      const redirectUrl = `${siteUrl(path)}?${newSearch}`;

      const session = await klarna.createSession({
        data: {
          totalPrice: isGiftShop ? shoppingCart.totalPrice : amount,
          shippingPrice: isGiftShop ? shoppingCart.shippingPrice : 0,
          redirectUrl: redirectUrl,
          orderLines: isGiftShop
            ? shoppingCart.items.map((x) => ({
                name: x.variant?.name,
                price: x.price,
                quantity: x.quantity,
                period: x.variant?.period || "Once"
              }))
            : [
                {
                  name,
                  price: amount,
                  quantity: 1,
                  period
                }
              ]
        }
      });

      const paymentMethodCategories = session.payment_method_categories || [];

      paymentMethodCategory.current = paymentMethodCategories[0]?.identifier;

      setOptionsCache((state) => ({
        ...state,
        [period]: session
      }));

      return session;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      name,
      optionsCache,
      period,
      redirectPath,
      shoppingCart.items,
      shoppingCart.totalPrice
    ]
  );

  const klarnaPaymentsUpdateSession = useCallback(
    async (amount: number) => {
      const session = optionsCache[period];

      if (session) {
        await klarna.updateSession(session.id, amount);
      }
    },
    [optionsCache, period]
  );

  const klarnaPaymentsInit = useCallback(
    async (amount: number, isGiftShop: boolean) => {
      const options = await createInitOptions(amount, isGiftShop);
      const instance = await getInstance();

      instance?.Payments.init(options);

      return options;
    },
    [createInitOptions, getInstance]
  );

  const klarnaPaymentsLoad = useCallback(
    async (amount?: number | null) => {
      setIsReady(false);

      const data = amount
        ? {
            purchase_currency: 'SEK',
            purchase_country: 'SE',
            locale: 'sv-se',
            order_lines: [
              {
                name,
                quantity: 1,
                total_amount: amount * 100,
                unit_price: amount * 100
              }
            ],
            order_amount: amount * 100
          }
        : {};

      if (!container.current) {
        console.log('klarnaPaymentsLoad was run but container not set');
        return {
          show_form: false
        };
      }

      const instance = await getInstance();
      const options: LoadOptions = {
        container: container.current,
        payment_method_category: paymentMethodCategory.current
      };

      return new Promise<LoadCallbackResponse>((resolve) => {
        instance?.Payments.load(options, data, (res) => {
          setShowForm(res.show_form);
          setIsReady(res.show_form);
          resolve(res);
        });
      });
    },
    [name, getInstance]
  );

  const klarnaPaymentsAuthorize = useCallback(
    async (data: Record<string, unknown> = {}) => {
      const instance = await getInstance();
      const options: AuthorizeOptions = {
        payment_method_category: paymentMethodCategory.current,
        auto_finalize: true
      };

      return new Promise<AuthorizeCallbackResponse>((resolve) => {
        instance?.Payments.authorize(options, data, (res) => {
          setShowForm(res.show_form);
          setIsReady(res.show_form);
          resolve(res);
        });
      });
    },
    [getInstance, paymentMethodCategory]
  );

  return useMemo<KlarnaPayments>(
    () => ({
      showForm,
      isReady,
      container,
      sessionId: optionsCache[period]?.id,
      handlers: {
        klarnaPaymentsInit,
        klarnaPaymentsLoad,
        klarnaPaymentsAuthorize,
        klarnaPaymentsUpdateSession,
        klarnaPaymentsPrefetch: getInstance
      }
    }),
    [
      showForm,
      isReady,
      optionsCache,
      period,
      klarnaPaymentsInit,
      klarnaPaymentsLoad,
      klarnaPaymentsAuthorize,
      klarnaPaymentsUpdateSession,
      getInstance
    ]
  );
}
