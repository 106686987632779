import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { ThankYou } from './ThankYou';
import { Form } from './Form';

import { set } from '~/App/helpers/cookie';

import { CloseButton } from './components/CloseButton';
import { Footer } from './components/Footer';
import { TinyHeader } from '~/App/components/TinyHeader';
import { useQuery } from '~/App/shared/hooks/use-query';
import { featureIsEnabled } from '~/App/helpers/feature-is-enabled';
import { scrollAnimation } from '~/App/helpers/animations';

import { useScrollLock } from '~/App/contexts/ScrollLock';
import { useAppContext } from '~/App/contexts/App';

type TakeOverProps = {
  showThankYou: boolean;
};

const TakeOver = styled.div<TakeOverProps>`
  position: fixed;
  background-color: #001489;
  overflow: auto;
  width: 100vw;
  height: 100vh;
  z-index: 800;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ showThankYou }) =>
    showThankYou ? 'flex-start' : 'space-between'};
  overflow-y: auto;
`;

const Center = styled.div`
  margin: 2.5rem 0 2rem;
  text-align: center;
`;

const HideImbox = createGlobalStyle`
  #imbox-container {
    opacity: 0;
  }
`;

const includedUrls = [
  '/stod-oss',
  '/minska-risken',
  '/forskning',
  '/rad-och-stod',
  '/om-oss'
];

type Query = {
  source?: string;
  gala?: string | boolean;
};

type Props = {
  children: ReactNode;
};

export function Takeover({ children }: Props) {
  const location = useLocation();
  const query = useQuery<Query>();
  const scrollLock = useScrollLock();
  const cookies = useAppContext((x) => x.cookies);
  const navigate = useNavigate();

  const [isVisible, setVisible] = useState(() => {
    if (query.gala) {
      return true;
    }

    if (location.pathname === '/manadsgivare-nu') {
      return true;
    }

    if (query.gala === false) {
      return false;
    }

    if (cookies._cf_takeover) {
      return false;
    }

    if (featureIsEnabled('FEATURE_KLARNA_TAKEOVER')) {
      for (const url of includedUrls) {
        if (location.pathname.startsWith(url) || location.pathname === '/') {
          return true;
        }
      }
    }

    return false;
  });

  const showThankYou = useMemo(() => query.gala === 'tack', [query.gala]);

  const handleClose = useCallback(
    (url?: string) => () => {
      if (url) {
        navigate(url);
      }

      set('_cf_takeover', 'true', 20 / 60);
      setVisible(false);
      scrollAnimation(0);
    },
    [navigate]
  );

  useEffect(() => (isVisible ? scrollLock.lock() : undefined), [
    isVisible,
    scrollLock
  ]);

  return useMemo(() => {
    if (!isVisible) {
      return children;
    }

    const closeButton = (
      <Center>
        <CloseButton
          onClose={handleClose('/rad-och-stod')}
          text="Läs mer om Cancerlinjen"
        />
      </Center>
    );

    const headerCloseButton = (
      <Center>
        <CloseButton onClose={handleClose('/')} text="Till cancerfonden.se" />
      </Center>
    );

    return (
      <>
        <HideImbox />
        <TakeOver showThankYou={showThankYou}>
          <TinyHeader onClick={handleClose()} children={headerCloseButton} />
          {showThankYou ? (
            <ThankYou children={closeButton} onClose={handleClose} />
          ) : (
            <Form
              source={query.source}
              redirectPath="/stod-oss/bli-manadsgivare/tack"
              children={closeButton}
            />
          )}
          {showThankYou ? null : <Footer />}
        </TakeOver>
        {children}
      </>
    );
  }, [isVisible, showThankYou, query.source, children, handleClose]);
}
